
const Boxes = {
  box1: function () {
   JXG.Options.board.minimizeReflow = 'none';
	 var brd2 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-10, 14, 10, -6],
    keepaspectratio: true, axis:true, ticks:{visible:false},
    grid:true, showCopyright:false, showNavigation:false,
    pan:{enabled:false}, zoom:{enabled:false}});
brd2.suspendUpdate();
var resize1 = function () {
  brd2.resizeContainer(brd2.containerObj.clientWidth, brd2.containerObj.clientHeight, true);
  brd2.fullUpdate();};
window.addEventListener("resize", resize1);
brd2.options.layer['image'] =14;
var vis=0;

//Title and subtitle
brd2.create('text', [0, 13, 'Zeros of a Polynomial'],{display:'internal', highlight:false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd2.canvasWidth/800.)}},);
brd2.create('text', [0, 12, '(Part I: Graphical Illustration)'],{visible:()=>vis==0,highlight:false, display:'internal', fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(26*brd2.canvasWidth/800.)}},);
brd2.create('text', [0, 12, '(Part II: Direct Verification)'],{visible:()=>vis==1,highlight:false, display:'internal', fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(26*brd2.canvasWidth/800.)}},);

//Variables
var qa = [0, 0, 4, 9, -2, 9, -7, 4, 8, -7, 4, 0, 0, 0, 0 , 0];
var qb = [1, 2, -2, -3, 4, 2, -7, 8, -8, 0, -5, 5, 3, -3, -5, 2];
var qc = [1, -3, -2, 0, 4, -5, 1, -4, -5, 5, -4, 2, 5 , 1, 2, -3];
var n = 5;
var a1 = 1;
var b1 = -3;
var c1 = 1;
var o1 = 2;
var txta1 = '1 x^2';
var txtb1 = '- 3 x ';
var txtc1 = '+ 1';
var r1 = 0;
var r2 = 0;
//var roots = [0];

//Shuffle image
var shuffle1 = brd2.create('image', ['/assets/shuffle.svg', [-0.75, -5], [1.5, 1.5]], {visible: true, fixed: true});
shuffle1.setLabel('Tap to Shuffle')
shuffle1.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
shuffle1.on('over', function () {this.label.setAttribute({visible:true});});
shuffle1.on('out', function () {this.label.setAttribute({visible:false});});

//Shuffle function
shuffle1.on('down',function(){

    x1txt.setAttribute({visible: false});
    x2txt.setAttribute({visible: false});
    root1Point.setAttribute({visible: false});
    root2Point.setAttribute({visible: false});
    pDragMe.moveTo([-10,5], 10);


    o1=0;
    while(o1 < 2){
        o1 = Math.round(Math.random()*2);
    }

        n = Math.floor(Math.random()*qa.length);

        a1 = qa[n];
        b1 = qb[n];
        c1 = qc[n];

        if(a1== 0){
            txta1 = ' ';

        }
        else if(a1 != 0){
            txta1 = a1 + ' x^' + o1 + ' ';
        }

        //b1 = Math.round(Math.random()*20-10);

        if(b1== 0){
            txtb1 = ' ';
        }
        else if(b1 > 0){
            txtb1 = '+ ' + b1 + ' x ';
        }

        else{
            txtb1 = ' ' + b1 + ' x ';
        }

        //c1 = Math.round(Math.random()*10-5);

        if(c1 == 0){
            txtc1 = ' ';
        }
        else if(c1>= 0){
            txtc1 = '+' + c1;
        }
        else{
            txtc1 = c1;
        }
/*
    var equation = a1 + '*x^' + o1  + txtb1 + '*x' + txtc1 + '=0';
    //console.log('equation:',equation)
    roots = nerdamer.solveEquations(equation,'x');
    var num_roots=roots.length;
    //console.log('num roots=',roots.length)
    for(var r=0;r<num_roots;r++){
        console.log('roots',r,nerdamer(roots[r].totxting()).evaluate().text());
    }
    //console.log('roots2',roots.totxting());
*/
});

//GRAPH
var graph = brd2.create('functiongraph', [function(x){ return a1*Math.pow(x,o1)+b1*x + c1;}, -20, 20],{strokeColor:'grey', strokeWidth:2});

//First root
var axisX = brd2.create('line', [[-5, 0], [5,0]], {visible: false, fixed: true});
var zero1 =brd2.create('intersection',[axisX, graph, 0],{visible: false, size:4, name:'Zero', withLabel:true, face:'square', fillColor:'yellow', txtokeColor:'black', shadow:true});


//Roots
var root1Point =brd2.create('point',[function(){

    if(isNaN(zero1.X())){

    r1 = 20;
    //console.log('IS NAN' , r1);
}

else{
    if(o1 == 2 && a1 != 0){

        r1 = (-b1+Math.pow(b1*b1-4*a1*c1, 0.5))/(2*a1);
        //console.log('CUADRATIC' , r1);
        //console.log('a' , a1);
        //console.log('b' , b1);
        //console.log('c' , c1);
    }
    else if(a1 == 0){
        r1 = -c1/b1;
        //console.log('LINEAL' , r1);
    }
    else{
        r1 = 20;
        //console.log('IDK' , r1);
    }
}

return r1;

}, 0],{visible: false, size:3, name:'X_1', withLabel:true, fillColor:'yellow', strokeColor:'black', shadow:true, label: {color:'blue', anchorX:'middle', offset:[0, -15], fontSize:function(){return 14*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});


var root2Point =brd2.create('point',[function(){

    if(isNaN(zero1.X())){

    r2 = 20;
    //console.log('IS NAN' , r2);
}

else{
    if(o1 == 2 && a1 != 0){

        r2 = (-b1-Math.pow(b1*b1-4*a1*c1, 0.5))/(2*a1);
        //console.log('CUADRATIC' , r2);
        //console.log('a' , a1);
        //console.log('b' , b1);
        //console.log('c' , c1);
    }
    else{
        r2 = 20;
        //console.log('IDK' , r2);
    }
}

return r2;

}, 0],{visible: false, size:3, name:'X_2', withLabel:true, fillColor:'yellow', strokeColor:'black', shadow:true, label: {anchorX:'middle', offset:[0, -15], color:'blue', fontSize:function(){return 14*brd2.canvasHeight/800}, cssStyle:'fontFamily:Oswald;'}});


//Tetxs of polynomials
brd2.create('text', [-5, 8, 'Your Polynomial'],{display:'internal', highlight:false, fixed: true, anchorX: 'middle', anchorY: 'bottom', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(26*brd2.canvasWidth/800.)}});
var polynomial11 = brd2.create('text', [-5, 7, function(){return  'f(x) = ' + txta1 + txtb1 + txtc1 }],{visible: true, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}},);
var txta11 ='';
var txtb11 ='';
var txtc11 ='';
var polynomial12 = brd2.create('text', [-5, -1, function(){

    if(isNaN(zero1.X())){
      return '';
    }

    else{

        if(a1== 0){
            txta11 = '';
        }
        else{
            txta11 = a1 + ' (' + root1Point.X().toFixed(2)+ ')^' + o1 + ' ';
        }

        if(b1== 0){
            txtb11 = '';
        }
        else if(b1 > 0){
            txtb11 = ' + '  + b1 + ' (' + root1Point.X().toFixed(2)+ ') ';
        }
        else{
            txtb11 = b1 + ' (' + root1Point.X().toFixed(2)+ ') ';
        }

        if(c1== 0){
            txtc11 = '';

        }
        else if(c1 > 0){
            txtc11 = ' + '  + c1;

        }
        else{
            txtc11 = c1;

        }
        return 'f(X_1) = ' + txta11 + txtb11 + txtc11 + ' = 0';
    }

    }],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd2.canvasWidth/500.)}},
);


var polynomial13 = brd2.create('text', [-5, -3, function(){

    if(isNaN(zero1.X())){
      return '';
    }

    else{

        if(a1== 0){
            txta11 = '';
        }
        else{
            txta11 = a1 + ' (' + root2Point.X().toFixed(2)+ ')^' + o1 + ' ';
        }

        if(b1== 0){
            txtb11 = '';
        }
        else if(b1 > 0){
            txtb11 = ' + '  + b1 + ' (' + root2Point.X().toFixed(2)+ ') ';
        }
        else{
            txtb11 = b1 + ' (' + root2Point.X().toFixed(2)+ ') ';
        }

        if(c1== 0){
            txtc11 = '';

        }
        else if(c1 > 0){
            txtc11 = ' + '  + c1;

        }
        else{
            txtc11 = c1;

        }
        return 'f(X_2 ) = ' + txta11 + txtb11 + txtc11 + ' = 0';
    }

    }],{visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(14*brd2.canvasWidth/500.)}},
);


var pDragMe = brd2.create('glider', [-10 , 5, graph],{name:'P(Drag me!)', fillColor:'white', strokeColor:'black', size:3, attractors: [root1Point, root2Point], attractorDistance:0.75, snatchDistance:0.75, withLabel:true, fixed:false, label:{visible:false, color:'blue', offset:[-10,20], cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}}});
//brd2.create('point', [0, ()=>pDragMe.Y()], {name:'y', size:1, color:'black',label:{color:'red', offset:[5,10],cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}}});
//brd2.create('point', [()=>pDragMe.X(), 0], {name:'x', size:1, color:'black',label:{color:'red', offset:[0, -15],cssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(16*brd2.canvasWidth/800.)}}});
brd2.create('segment', [pDragMe, [0, ()=>pDragMe.Y()]],{dash:1, strokeWidth:1, strokeColor:'grey'});
brd2.create('segment', [pDragMe, [()=>pDragMe.X(), 0]],{dash:1, strokeWidth:1, strokeColor:'grey'});
//
brd2.create('text', [-5, 5, 'Value @ Point P'],{display:'internal', highlight:false, fixed: true, anchorX: 'middle', anchorY: 'bottom', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(26*brd2.canvasWidth/800.)}});

//
var pDragMetxt = brd2.create('text', [-5, 4, function(){

    if(isNaN(zero1.X())){
        return '';
      }

      else{
          return ' y = f(x =' + (pDragMe.X()).toFixed(2) + ') = ' + (a1*Math.pow(pDragMe.X(),o1)+b1*pDragMe.X() + c1).toFixed(2)
        }
}],{fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(24*brd2.canvasWidth/800.)}},);


//Tetx of the X roots
var x1txt = brd2.create('text', [-6.5, 2.5, function(){return 'X_1 = ' + root1Point.X().toFixed(2)}],{fontColor:'blue', visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(20*brd2.canvasWidth/800.)}},);
var x2txt = brd2.create('text', [-4, 2.5, function(){return 'X_2 = ' + root2Point.X().toFixed(2)}],{fontColor:'blue', visible: false, fixed: true, anchorX: 'middle', anchorY: 'middle', CssStyle:'fontFamily:Oswald',fontSize:function(){return Math.round(20*brd2.canvasWidth/800.)}},);

//Roots appear
setInterval(checkRoot1, 100);
function checkRoot1(){

    if(pDragMe.X() == root1Point.X() )
    {
        root1Point.setAttribute({visible: true});
        x1txt.setAttribute({visible: true});

    }

}

setInterval(checkRoot2, 100);
function checkRoot2(){

    if(pDragMe.X() == root2Point.X() )
    {
        root2Point.setAttribute({visible: true});
        x2txt.setAttribute({visible: true});

    }

}
/*
pDragMe.on('over', checkRoot1);
pDragMe.on('over', checkRoot2);
*/

//REDO AND UNDO
var redo = brd2.create('image', ['/assets/redo.svg', [2.25, -5], [1.5 , 1.5]], {visible:()=>i<=0, fixed: true});
var undo = brd2.create('image', ['/assets/undo.svg', [function(){return -redo.X()-1.5}, function(){return redo.Y()}], [1.5 , 1.5]], {visible:()=>i>=1, fixed: true});

pDragMe.on('over', function () {this.label.setAttribute({visible:true});});
pDragMe.on('out', function () {this.label.setAttribute({visible:false});});
//
redo.setLabel('Next')
redo.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
redo.on('over', function () {this.label.setAttribute({visible:true});});
redo.on('out', function () {this.label.setAttribute({visible:false});});

undo.setLabel('Previous')
undo.label.setAttribute({visible:false, offset:[15, -15], CssStyle:'fontFamily:Oswald', fontSize:16});
undo.on('over', function () {this.label.setAttribute({visible:true});});
undo.on('out', function () {this.label.setAttribute({visible:false});});


var i =0;
var maxSteps = 1;

function updateStepTexts(){
    eraseText();

    if (i<=0){
        polynomial11.setAttribute({visible: true});
		  pDragMetxt.setAttribute({visible: true});
		//x1txt.setAttribute({visible:true});
        //x2txt.setAttribute({visible:true});

    }
    if(i==1){

        polynomial11.setAttribute({visible: true});
        polynomial12.setAttribute({visible: true});
        polynomial13.setAttribute({visible: true});
        pDragMetxt.setAttribute({visible: true});
		//x1txt.setAttribute({visible:true});
        //x2txt.setAttribute({visible:true});

    }
}

function eraseText(){

    polynomial11.setAttribute({visible: false});
    polynomial12.setAttribute({visible: false});
    polynomial13.setAttribute({visible: false});
    pDragMetxt.setAttribute({visible: false});
    pDragMe.moveTo([-10,5], 10);
 //   x1txt.setAttribute({visible:false});
 //   x2txt.setAttribute({visible:false});

}

redo.on('down',function(){
    i = i+1;
	vis+=1;
    if (i > maxSteps){
        i = maxSteps;
		vis=maxSteps;
    }
    updateStepTexts();
});

undo.on('down',function(){
    i=i-1;
	vis-=1;
    if (i < 0){
        i = 0;
	    vis=0;
    }
    eraseText();
    updateStepTexts();
});



/*
var root0 = function(){

    var value=nerdamer(roots[0].totxting()).evaluate().text();

    if(value.includes('i')){
        return 0;
    }

    else{
        return value;
    }
}


var root1 = function(){

    var value=nerdamer(roots[1].totxting()).evaluate().text();

    if(value.includes('i')){
        return 1;
    }

    else{
        return value;
    }
}


var zero2 = brd2.create('point', [root0,0],{face:'o' , name: function(){if(isNaN(zero1.Y())){
                                                                            return '';
                                                                        } else{ return 'ROOT';}},  size:4, withLabel:true, face:'square', fillColor:'yellow', txtokeColor:'black', shadow:true});

var zero3 = brd2.create('point', [root1,0],{face:'o' , name: 'aa',  size:4, withLabel:true, face:'square', fillColor:'yellow', txtokeColor:'black', shadow:true});

*/


brd2.unsuspendUpdate();

}
}
export default Boxes;
