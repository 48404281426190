<template>
  <div>
    <h3 ref="intro">
      Zero of a Polynomial
    </h3>
    <p>
      Zeros of a polynomial, say \(p(x)\),  are the values of variable \(x\) at which
      the polynomial becomes zero.
      Thus, if \(x=x_0\) is a zero of the polynomial \(p(x)\) then it must satisfy the following:
      $$p(x=x_0) = 0$$
      The zeros of a polynomial are sometimes also called its roots.
      Depending on the degree of the polynomial, it can have one or more than one zeros &mdash;
      as we will discuss in the following section.
    </p>
    <h3 ref="formal">
      Number of Zeros of a Polynomial
    </h3>
    <p>
      The fundamental theorem of algebra states that a non-zero polynomial has
      the same number of zeros (or roots) as its degree.
      This requires that we account for multiplicity of zeros (i.e. repeated zeros).
      Thus, a polynomial of degree \(n\) will have \(n\) zeros or roots. Some of these zeros may be repeated or complex.
      Below are few specific examples.
      <ul class="a">
        <li> <h5> Linear Polynomial (Degree =1) </h5> </li>
        A linear polynomial (i.e. a polynomial of degree 1) will have one zero.
        To give an example, let's consider the linear polynomial $$p(x) = 2x-6$$
        This polynomial has exactly one zero, which is \(x=3\).
        <li> <h5>Quadratic Polynomial (Degree =2)</h5> </li>
        A quadratic polynomial (i.e. a polynomial of degree 2) will have two zeros
        (note that sometimes these zeros may be repeated).
        Let's first discuss the example of a quadratic polynomial that has two distinct zeros.
        $$q(x) = x^2 -6x +8$$
        After a little rearrangement, \(q(x)\) can be rewritten as:
        $$q(x) = (x-4)(x-2)$$
        You can see that this polynomial becomes zero at \(x=2\) and at \(x=4\). These are the two zeros of this polynomial.
        <br> Next, we discuss the example of a quadratic polynomial that has two repeated (or identical) zeros.
        Consider the following polynomial:
        $$r(x) = x^2 -4x +4$$
        This polynomial can be rearranged as follows:
        $$r(x) = (x-2)(x-2)$$
        Thus, this polynomial has two repeated zeros at \(x =2\).
      </ul>
    </p>
    <h3 ref="playgraph">
      MagicGraph | Zeros of a Polynomial
    </h3>
    <p>
      Through this visually interactive MagicGraph, students will learn about the
      zeros of the polynomial, and how to calculate them using the graphical method.
    </p>
    <h5> To Get Started: </h5>
    <p>
      You are given a polynomial function named f(x).
      On this MagicGraph, you will also see the graph representing this polynomial function.
      You goal is to identify the zeros (or roots) of this polynomial using the graphical method (described below).<br>
      Point 'P' is a movable point that can glide along the polynomial curve.
      As you drag this point along the polynomial curve, the MagicGraph will
      automatically calculate and display the value of the polynomial at point P.
      Identify the location (or locations) of point P on the graph at which the polynomial becomes zero.
      These are going to be the zeros (or roots) of the polynomial function f(x).
    </p>
    <h5> To Explore:</h5>
    <p>
      After finding the zeros of one polynomial function, tap on the shuffle button to create a new polynomial function
      Each time you tap on the shuffle button,
      the MagicGraph generates a new polynomial for you to practice with.
      Using the graphical method described above, find the zeros of the polynomial &mdash; practice as many times as you want.
    </p>
    <v-responsive>
      <v-layout justify-center>
        <div id="jxgbox1" class="edliy-box-about" />
      </v-layout>
    </v-responsive>
  </div>
</template>
<script>
import Boxes from './Boxes.js'
export default {
  name: 'ForcesStresses',
  created: function () {
    this.$store.commit('navigation/resetState');
    let title = 'Zeros of a Polynomial';
    this.$store.commit('navigation/changeTitle', title);
    this.$store.commit('navigation/changeMenu', title);
    let newTopics = [
      {title: 'zeros of a Polynomial', img:'/assets/number-1.svg', action: () => this.goto('intro')},
      {title: 'Number of zeros', img:'/assets/number-2.svg', action: () => this.goto('formal')},
      {title: 'MagicGraph', img:'/assets/touch.svg', action: () => this.goto('graphical')},
    ];
    this.$store.commit('navigation/replaceTopics', newTopics);
    let newshowhome = false;
    this.$store.commit('navigation/toggleshowhome', newshowhome);
    let newMath =true;
    this.$store.commit('navigation/replaceMath', newMath);
    let newLeftArrow =true;
    this.$store.commit('navigation/replaceLeftArrow', newLeftArrow);
    let newModule=true;
    this.$store.commit('navigation/replaceModule', newModule);
  },
  mounted () {
    MathJax.Hub.Queue(["Typeset", MathJax.Hub]);
    Boxes.box1();
  },
  metaInfo() {
  return{ title: 'Roots of a Polynomial',
          titleTemplate: '%s - Learn interactively',
          meta: [ {name: 'viewport', content: 'width=device-width, initial-scale=1'},
                  {name: 'description', content: 'Learn interactively how to draw a free body diagram'}
                ]
        }
   },
}
</script>

<style lang="scss">
@import 'src/styles/edliy-box.scss';
@import 'src/styles/subtopic-menu.scss';
@import 'src/styles/edliy-box-about.scss';
</style>
